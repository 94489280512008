import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import Footer from "../../Components/Common/Footer";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import FooterMobile from "../../Components/Common/FooterMobile";
import Otp from "../../Components/OTP/OTP";
class OtpPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>
                <Otp/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}

export default OtpPage;