import React, {Component,Fragment} from 'react';
import { FaTrash } from "react-icons/fa";
import FullScreenLoader from "../Common/FullScreenLoader";
import axios from "axios";
import {
    removeItemSuccess,
    requestFail,
    RequiredCartList,
    RequiredCity,
    RequiredLocation, RequiredReceiverAddress, RequiredReceiverName, RequiredReceiverPhone, RequiredShippingPoint
} from "../../Helper/ToastMassageHelper";
import {CartDeleteURL} from "../../APIServices/CartDeleteRequest";
import {CartListURL} from "../../APIServices/CartListRequest";
import {Redirect} from "react-router";
import CartListPlaceholder from "./CartListPlaceholder";
import SessionHelper from "../../Helper/SessionHelper";
import {LocationListURL} from "../../APIServices/LocationListRequest";
import {ShippingPointURL} from "../../APIServices/shippingPointRequest";
import EmptyCartList from "./EmptyCartList";
import ValidationHelper from "../../Helper/ValidationHelper";
class CartList extends Component {

    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            DataList:null,
            RedirectCheckOut:false,
            PageRefreshStatus:false,
            locationList:null,
            shippingPointList:null,
            cityValue:"",
            locationValue:"",
            shippingPointValue:"",
            nameValue:"",
            mobileValue:"",
            addressValue:"",
            nodata:false,
            RedirectHome:false,
        }
    }

    RedirectPageRefresh=()=>{
        if(this.state.PageRefreshStatus===true){
            return (
                <Redirect to="/CartList"/>
            )
        }
    }
    RedirectToHomePage=()=>{
        if(this.state.RedirectHome===true){
            return (
                <Redirect to="/"/>
            )
        }
    }
    RedirectCheckOut=()=>{
        if(this.state.RedirectCheckOut===true){
            return (
                <Redirect to="/checkOut"/>
            )
        }
    }

    // Input On changes
    cityOnchange=(event)=>{
        let city=event.target.value;
        this.setState({cityValue:city});
    }
    locationOnChange=(event)=>{
        let location= event.target.value;
        this.setState({locationValue:location})
        this.setState({isLoading:""})
        axios.get(ShippingPointURL(location)).then((res)=>{
            if(res.status===200){
                this.setState({shippingPointList:res.data})
                this.setState({isLoading:"d-none"})
            }
            else{
                this.setState({isLoading:"d-none"})
                requestFail();
            }
        }).catch((err)=>{
            this.setState({isLoading:"d-none"})
            requestFail();
        })
    }
    shippingPointOnChange=(event)=>{
        let shippingPoint=event.target.value;
        this.setState({shippingPointValue:shippingPoint})
    }
    nameOnChange=(event)=>{
        let name=event.target.value;
        this.setState({nameValue:name})
    }
    mobileOnChange=(event)=>{
        let mobile=event.target.value;
        this.setState({mobileValue:mobile})
    }
    addressOnChange=(event)=>{
        let address=event.target.value;
        this.setState({addressValue:address})
    }


    next=()=>{

        let  cityValue=this.state.cityValue;
        let  locationValue=this.state.locationValue;
        let  shippingPointValue=this.state.shippingPointValue;
        let  nameValue=this.state.nameValue;
        let  mobileValue=this.state.mobileValue;
        let  addressValue=this.state.addressValue;

       if(this.state.nodata===true){
           RequiredCartList();
       }
       else if(cityValue.length===0){
           RequiredCity();
       }
       else if(locationValue.length===0){
           RequiredLocation();
       }
       else if(shippingPointValue.length===0){
           RequiredShippingPoint();
       }
       else if(shippingPointValue.length===0){
           RequiredShippingPoint();
       }
       else if(nameValue.length===0){
           RequiredReceiverName();
       }
       else if(mobileValue.length===0){
           RequiredReceiverPhone();
       }
       else if(!ValidationHelper.MobileRegx.test(mobileValue)){
           RequiredReceiverPhone();
       }
       else if(addressValue.length===0){
           RequiredReceiverAddress();
       }
       else{
           SessionHelper.setCity(cityValue);
           SessionHelper.setLocation(locationValue);
           SessionHelper.setShippingPoint(shippingPointValue);
           SessionHelper.setReceiverName(nameValue);
           SessionHelper.setReceiverMobile(mobileValue);
           SessionHelper.setReceiverAddress(addressValue);
           this.setState({RedirectCheckOut:true})
       }
    }


    componentDidMount() {
        this.cartList();
        this.locationList();
    }

    cartList=()=>{
        axios.get(CartListURL()).then((response)=>{
            if(response.status===200 && response.data.length>0){
                this.setState({DataList:response.data})
            }
            else {
                this.setState({nodata:true})
            }
        }).catch((err)=>{
            this.setState({nodata:true})
        })
    }

    locationList=()=>{
        axios.get(LocationListURL()).then((res)=>{
            if(res.status===200){
                this.setState({locationList:res.data})
            }
            else{
                requestFail()
                this.setState({RedirectHome:true})
            }
        }).catch((err)=>{
            requestFail()
            this.setState({RedirectHome:true})
        })
    }

    remove=(id)=>{
            this.setState({isLoading:""})
            axios.get(CartDeleteURL(id)).then((res)=>{
                if(res.status===200 && res.data===1){
                    this.setState({isLoading:"d-none"})
                    removeItemSuccess();
                    this.setState({PageRefreshStatus:true})
                }
                else {
                    requestFail();
                    this.setState({isLoading:"d-none"})
                }
            }).catch((err)=>{
                    requestFail();
                    this.setState({isLoading:"d-none"})
            })
    }




    render() {


        // Location Option View
        let LocationList=this.state.locationList;
        let LocationListView=<option></option>
        if(LocationList!==null){
            LocationListView=LocationList.map((list,i)=>{
                return(
                    <option value={list['location']}>{list['location']}</option>
                )
            })
        }


        // Shipping Point View

        let ShippingPointList=this.state.shippingPointList;
        let ShippingPointView=<option></option>
        if(ShippingPointList!==null){
            ShippingPointView=ShippingPointList.map((list,i)=>{
                return(
                    <option value={list['shipping_point']}>{list['shipping_point']}</option>
                )
            })
        }
        let DataList=this.state.DataList;
        let DataView=<CartListPlaceholder/>
            if(DataList!==null && this.state.nodata===false){
                DataView=  DataList.map((list,i)=>{
                    let discountPrice=list['discount_price'];
                    let unitPrice=list['unit_price'];

                    let PriceView= <h6 className="cart-list-text">মূল্যঃ {discountPrice} টাকা</h6>
                    if(discountPrice===null){
                        PriceView= <h6 className="cart-list-text">মূল্যঃ {unitPrice} টাকা</h6>
                    }

                    if(list['cat_code']===process.env.REACT_APP_MANGO_CODE){
                        return(
                            <div className="col-lg-12 animated slideInUp col-md-12 col-sm-12 col-12 p-1">
                                <div className="container shadow-sm bg-white">
                                    <div className="row">
                                        <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                            <img className="w-75" src={list['img']}/>
                                            {PriceView}
                                        </div>
                                        <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                            <div className="container">
                                               <div className="row">
                                                   <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                       <h6 className="cart-list-text">নামঃ {list['product_name']}</h6>
                                                       <h6 className="cart-list-text">ঝুড়ির সাইজঃ  {list['lot_size']} {list['product_unit']}</h6>
                                                       <h6 className="cart-list-text">পরিমাণঃ {list['product_quantity']} টি </h6>
                                                   </div>
                                                   <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                       <h6 className="cart-list-text">প্যাকেজিংঃ {list['packing_charge']} টাকা </h6>
                                                       <h6 className="cart-list-text">মোট মূল্যঃ {list['total_price']} টাকা </h6>
                                                       <button onClick={this.remove.bind(this,list['id'])} className="btn btn-sm btn-danger"><FaTrash/> রিমুভ</button>
                                                   </div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return(
                            <div className="col-lg-12 animated slideInUp col-md-12 col-sm-12 col-12 p-1">
                                <div className="container shadow-sm bg-white">
                                    <div className="row">
                                        <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                            <img className="w-75" src={list['img']}/>
                                            {PriceView}
                                        </div>
                                        <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                        <h6 className="cart-list-text">নামঃ {list['product_name']}</h6>
                                                        <h6 className="cart-list-text">পরিমাণঃ {list['product_quantity']} টি </h6>
                                                        <h6 className="cart-list-text">প্যাকেজিংঃ {list['packing_charge']} টাকা </h6>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                        <h6 className="cart-list-text">মোট মূল্যঃ {list['total_price']} টাকা </h6>
                                                        <button onClick={this.remove.bind(this,list['id'])} className="btn btn-sm btn-danger"><FaTrash/> রিমুভ</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            else if(this.state.nodata===true){
                DataView=<EmptyCartList/>
            }

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 p-0 col-md-8 col-sm-12 col-12">
                            <div className="container-fluid">
                                <div className="row">
                                    {DataView}

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-1 col-md-4 col-sm-12 col-12">
                            <div className="shadow-sm bg-white p-3">
                                <h5>চেক আউট করুন </h5>
                                <label className="form-label">শহর</label>
                                <select onChange={this.cityOnchange}  className="form-control">
                                    <option value="">শহর নির্বাচন করুন</option>
                                    <option value="InSideDhaka">ঢাকার ভেতরে</option>
                                    <option value="OutSideDhaka">ঢাকার বাইরে</option>
                                </select>

                                <label className="form-label" > অঞ্চল</label>
                                <select onChange={this.locationOnChange} className="form-control">
                                    <option value="">অঞ্চল নির্বাচন</option>
                                    {LocationListView}
                                </select>


                                <label className="form-label">শিপিং পয়েন্ট</label>
                                <select onChange={this.shippingPointOnChange} className="form-control">
                                    <option value="">শিপিং পয়েন্ট নির্বাচন</option>
                                    {ShippingPointView}
                                </select>

                                <label className="form-label"> গ্রাহকের নাম</label>
                                <input onChange={this.nameOnChange} type="text" className="form-control"/>
                                <label className="form-label"> মোবাইল নং </label>
                                <input  onChange={this.mobileOnChange} type="text" className="form-control"/>
                                <label className="form-label"> ঠিকানা</label>
                                <input onChange={this.addressOnChange} type="text" className="form-control"/>
                                <button onClick={this.next} className="btn btn-dark mt-2 btn-block">পরবর্তী ধাপে যান </button>

                            </div>
                        </div>
                    </div>
                </div>
                <FullScreenLoader isLoading={this.state.isLoading}/>
                {this.RedirectPageRefresh()}
                {this.RedirectCheckOut()}
                {this.RedirectToHomePage()}
            </Fragment>
        );
    }
}

export default CartList;