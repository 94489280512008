import React, {Component,Fragment} from 'react';

class OrderList extends Component {
    render() {
        return (
            <Fragment>

                <div className="container mt-3 mb-2 justify-content-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card blogDetailsCommentCard">

                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">সিটি নির্বাচন করুন</label>
                                            <select className="form-control" id="">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">প্রোডাক্টের নাম</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">প্রোডাক্টের বর্ণনা</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">প্রোডাক্টের পরিমান</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">প্রোডাক্টের দাম</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">সর্বমোট দাম</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">শিপিং চার্জ</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">মোট বাকি</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">মোবাইল নাম্বার</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">নাম</label>
                                            <input type="text" className="form-control" id="" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">পেমেন্ট মেথড</label>
                                            <select className="form-control" id="">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-levelText">মতামত লিখুন</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="1" ></textarea>
                                        </div>
                                    </div>
                                    <a className="btn btn-primary comment-btn">কনফার্ম অর্ডার</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

export default OrderList;