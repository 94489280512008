// Reviewed
import React, {Component,Fragment} from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import ReactHtmlParser from "react-html-parser";
import {BiCart,BiHeart} from "react-icons/bi";
import {FaShippingFast} from "react-icons/fa"
import axios from "axios";
import {addFavBody, addFavURL} from "../../APIServices/FavRequest";
import {
    AddCartSuccess,
    addFavSuccess, comingSoon, OnlyCartGeneralWarning, OnlyCartMangoWarning, OnlyCartTreeWarning,
    quantityRequired,
    requestFail,
    RequiredStock,
    sizeRequired
} from "../../Helper/ToastMassageHelper";
import SessionHelper from "../../Helper/SessionHelper";
import {Redirect} from "react-router";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import FullScreenLoader from "../Common/FullScreenLoader";
import {AddToCartBody, AddToCartURL} from "../../APIServices/AddToCartRequest";
class ProductDetails extends Component {

    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            previewImg:"0",
            PageRefreshStatus:false,
            PageRedirectStatus:false,
            RedirectToLogin:false,
            RedirectCartList:false,
        }
    }
    imgOnclick=(event)=>{
        let imgSrc= event.target.getAttribute('src');
        this.setState({previewImg:imgSrc})
    }
    addFav=(product_code)=>{
        if(SessionHelper.getLogin()!=='YES'){
                this.setState({RedirectToLogin:true})
        }
        else {
            this.setState({isLoading:""})
            axios.post(addFavURL(),addFavBody(product_code))
                .then((res)=>{
                    if(res.status===200 && res.data===1){
                        addFavSuccess();
                        this.setState({isLoading:"d-none"})
                    }
                    else {
                        requestFail();
                        this.setState({isLoading:"d-none"})
                    }
                })
                .catch((err)=>{
                    this.setState({isLoading:"d-none"})
                    requestFail();
                })
        }
    }
    addCart=(product_code,cat_code,Stock)=>{
        if(Stock==="NO"){
            RequiredStock();
        }
        else if(Stock==="CS"){
            comingSoon();
        }
        else if(SessionHelper.getLogin()!=='YES'){
            this.setState({RedirectToLogin:true})
        }

        else{
            let Size= this.Size.value;
            let Quantity= this.Quantity.value;
            if(Size.length===0){
                sizeRequired();
            }
            else if(Quantity.length===0){
                quantityRequired();
            }
            else{
                if(cat_code===process.env.REACT_APP_MANGO_CODE){
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartMango"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                AddCartSuccess();
                                this.setState({PageRefreshStatus:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartMangoWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }
                else if(cat_code===process.env.REACT_APP_TREE_CODE){
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartTree"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                AddCartSuccess();
                                this.setState({PageRefreshStatus:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartTreeWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }

                else {
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartGeneral"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                AddCartSuccess();
                                this.setState({PageRefreshStatus:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartGeneralWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }



            }
        }
    }
    RedirectToLoginPage=()=>{
        if(this.state.RedirectToLogin===true){
                return (
                    <Redirect to="/login"/>
                )
        }
    }
    RedirectPageRefresh=()=>{
        if(this.state.PageRefreshStatus===true){
            return (
                <Redirect to={SessionHelper.getProductLocation()}/>
            )
        }
    }
    RedirectCartListPage=()=>{
        if(this.state.RedirectCartList===true){
            return (
                <Redirect to="/CartList"/>
            )
        }
    }
    addOrder=(product_code,cat_code,Stock)=>{
        if(Stock==="NO"){
            RequiredStock();
        }
        else if(Stock==="CS"){
            comingSoon();
        }
        else if(SessionHelper.getLogin()!=='YES'){
            this.setState({RedirectToLogin:true})
        }
        else{
            let Size= this.Size.value;
            let Quantity= this.Quantity.value;
            if(Size.length===0){
                sizeRequired();
            }
            else if(Quantity.length===0){
                quantityRequired();
            }
            else{
                if(cat_code===process.env.REACT_APP_MANGO_CODE){
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartMango"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                this.setState({RedirectCartList:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartMangoWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }
                else if(cat_code===process.env.REACT_APP_TREE_CODE){
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartTree"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                this.setState({RedirectCartList:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartTreeWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }

                else {
                    this.setState({isLoading:""})
                    axios.post(AddToCartURL("AddToCartGeneral"),AddToCartBody(cat_code,product_code,Size,Quantity))
                        .then((res)=>{
                            this.setState({isLoading:"d-none"})
                            if(res.status===200 && res.data===1){
                                this.setState({RedirectCartList:true})
                            }
                            else if(res.status===200 && res.data==="exists"){
                                OnlyCartGeneralWarning();
                            }
                            else{
                                requestFail();
                            }
                        })
                        .catch((err)=>{
                            this.setState({isLoading:"d-none"});
                            requestFail();
                        })
                }

            }
        }
    }
    render() {


        let ProductData=this.props.DataList;
        let img1 =ProductData['ProductDetails'][0]['img1'];
        if(this.state.previewImg==="0"){
            this.setState({previewImg:img1})
        }
        let img2 =ProductData['ProductDetails'][0]['img2'];
        let img3 =ProductData['ProductDetails'][0]['img3'];
        let img4 =ProductData['ProductDetails'][0]['img4'];
        let shot_desc =ProductData['ProductDetails'][0]['shot_desc'];
        let title=ProductData['ProductList'][0]['title'];
        let product_code=ProductData['ProductList'][0]['product_code'];
        let star=ProductData['ProductList'][0]['star'];
        let Stock=ProductData['ProductList'][0]['stock'];
        let discount_type=ProductData['ProductList'][0]['discount_type'];
        let discount_percentage=ProductData['ProductList'][0]['discount_percentage'];
        let flat_discount=ProductData['ProductList'][0]['flat_discount'];
        let unit=ProductData['ProductList'][0]['unit'];
        let unit_price=ProductData['ProductList'][0]['unit_price'];
        let product_price=ProductData['ProductList'][0]['product_price'];
        let cat_code=ProductData['ProductList'][0]['cat_code'];
        let ProductLotList=ProductData['Lot'];


        // Start View
        let OneStarView=<span>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let TwoStarView=<span>
            <AiFillStar className="text-success"/>
             <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let ThreeStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>

        let StarView;

        if(star==="1"){
            StarView=OneStarView;
        }
        else if(star==="2") {
            StarView=TwoStarView;
        }
        else if(star==="3") {
            StarView=ThreeStarView;
        }
        else if(star==="4") {
            StarView=FourStarView;
        }
        else if(star==="5") {
            StarView=FiveStarView;
        }

        // Stock View
        let StockView=<h6 className="text-success">স্টকঃ আছে</h6>
        if(Stock==="NO"){
             StockView=<h6 className="text-danger">স্টকঃ নাই</h6>
        }
        else if(Stock==="CS"){
            StockView=<h6 className="text-primary">স্টকঃ শিগ্রই আসছে </h6>
        }

        // Discount View
        let DiscountView=<span className="ml-2"> </span>
        if(discount_type==="Percentage"){
            DiscountView=<h6 className="ml-2">ডিস্কাউন্টঃ {discount_percentage} % </h6>
        }
        else if(discount_type==="Flat"){
            DiscountView=<h6 className="ml-2">ডিস্কাউন্টঃ {flat_discount} টাকা </h6>
        }

        // Price View
        let PriceView=<h6>দামঃ <span className="text-secondary"><strike>{unit_price}</strike></span> {product_price} টাকা/{unit}</h6>
        if(product_price===null){
            PriceView=<h6>দামঃ {unit_price} টাকা/{unit}</h6>
        }


        //Lot View
        let LotView= <option selected value="NA">নেই</option>
        let LotDIV= "d-none";
        if(cat_code===process.env.REACT_APP_MANGO_CODE){
            let LotData=ProductLotList;

            if(LotData!==null){
                LotDIV=""
                LotView=LotData.map((list,i)=>{
                    return(
                        <option value={list['lot_quantity']}>{list['lot_quantity']} {unit}</option>
                    )
                })
            }
        }

        if(cat_code===process.env.REACT_APP_MANGO_CODE){
            return (
                <Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="row shadow-sm p-2 bg-white">
                                    <div className="p-1 animated fadeIn col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="container">
                                            <div className="row">
                                                <div className="p-0 m-0 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <InnerImageZoom
                                                        zoomType={"hover"}
                                                        zoomScale={1.8}
                                                        src={this.state.previewImg}
                                                        zoomSrc={this.state.previewImg} />
                                                </div>
                                                <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100" src={img2} alt=""/>
                                                </div>
                                                <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img3} alt=""/>
                                                </div>
                                                <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img4} alt=""/>
                                                </div>
                                                <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 col-lg-6 animated fadeIn col-md-6 col-sm-12 col-12">
                                        <h5 className="product-details-title p-0 m-0">{title}</h5>
                                        {StarView}
                                        <h6 className="product-details-sub-title">{ReactHtmlParser(shot_desc)}</h6>
                                        <div className="input-group">
                                            {StockView}
                                            {DiscountView}
                                        </div>
                                        {PriceView}

                                        <div className={LotDIV}>
                                            <label className="form-label">আমের পরিমান</label>
                                            <select ref={(input) => {this.Size = input;}} className="form-control">
                                                <option value="">পরিমান নির্বাচন করুন</option>
                                                {LotView}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="form-label">ঝুড়ির সংখ্যা</label>
                                            <select ref={(input) => {this.Quantity = input;}} className="form-control">
                                                <option value="">ঝুড়ির সংখ্যা নির্বাচন করুন</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                                <option value={10}>10</option>
                                            </select>
                                        </div>


                                        <div className="input-group mt-3">
                                            <button onClick={this.addCart.bind(this,product_code,cat_code,Stock)} className="btn btn-success card-order-btn m-1 "> <BiCart className="mx-1"/> কার্ট</button>
                                            <button onClick={this.addOrder.bind(this,product_code,cat_code,Stock)} className="btn btn-success card-order-btn m-1"> <FaShippingFast className="mx-1"/> অর্ডার</button>
                                            <button onClick={this.addFav.bind(this,product_code)} className="btn btn-success card-order-btn m-1"> <BiHeart className="mx-1"/> পছন্দ</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.RedirectToLoginPage()}
                    {this.RedirectPageRefresh()}
                    {this.RedirectCartListPage()}
                    <FullScreenLoader isLoading={this.state.isLoading}/>
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="row shadow-sm p-2 bg-white">
                                    <div className="p-1 animated fadeIn col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="container">
                                            <div className="row">
                                                <div className="p-0 m-0 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <InnerImageZoom
                                                        zoomType={"hover"}
                                                        zoomScale={1.8}
                                                        src={this.state.previewImg}
                                                        zoomSrc={this.state.previewImg} />
                                                </div>
                                                <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100" src={img2} alt=""/>
                                                </div>
                                                <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img3} alt=""/>
                                                </div>
                                                <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img4} alt=""/>
                                                </div>
                                                <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <img onClick={this.imgOnclick} className="w-100 " src={img1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-3 col-lg-6 animated fadeIn col-md-6 col-sm-12 col-12">
                                        <h5 className="product-details-title p-0 m-0">{title}</h5>
                                        {StarView}
                                        <h6 className="product-details-sub-title">{ReactHtmlParser(shot_desc)}</h6>
                                        <div className="input-group">
                                            {StockView}
                                            {DiscountView}
                                        </div>
                                        {PriceView}

                                        <div className={LotDIV}>
                                            <label className="form-label">ঝুড়ির সাইজ</label>
                                            <select ref={(input) => {this.Size = input;}} className="form-control">
                                                <option value="">সাইজ নির্বাচন করুন</option>
                                                {LotView}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="form-label">পরিমান</label>
                                            <select ref={(input) => {this.Quantity = input;}} className="form-control">
                                                <option value="">পরিমান নির্বাচন করুন</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                                <option value={9}>9</option>
                                                <option value={10}>10</option>
                                            </select>
                                        </div>


                                        <div className="input-group mt-3">
                                            <button onClick={this.addCart.bind(this,product_code,cat_code,Stock)} className="btn btn-success card-order-btn m-1 "> <BiCart className="mx-1"/> কার্ট</button>
                                            <button onClick={this.addOrder.bind(this,product_code,cat_code,Stock)} className="btn btn-success card-order-btn m-1"> <FaShippingFast className="mx-1"/> অর্ডার</button>
                                            <button onClick={this.addFav.bind(this,product_code)} className="btn btn-success card-order-btn m-1"> <BiHeart className="mx-1"/> পছন্দ</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.RedirectToLoginPage()}
                    {this.RedirectPageRefresh()}
                    {this.RedirectCartListPage()}
                    <FullScreenLoader isLoading={this.state.isLoading}/>
                </Fragment>
            );
        }

    }
}

export default ProductDetails;