import React, {Component,Fragment} from 'react';
import axios from "axios";
import ProductListPlaceholder from "../ProductList/ProductListPlaceholder";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {Link} from "react-router-dom";
import {IoBagHandleSharp} from "react-icons/io5";
import {removeFavBody, removeFavURL, selectFavURL} from "../../APIServices/FavRequest";
import {FiTrash2} from "react-icons/fi"
import {removeFavSuccess, removeItemSuccess, requestFail} from "../../Helper/ToastMassageHelper";
import FullScreenLoader from "../Common/FullScreenLoader";
class FavouriteList extends Component {

    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            DataList:null,
        }
    }


    componentDidMount() {
        window.scroll(0,0)
        this.selectFavList();
    }

    selectFavList(){
        axios.get(selectFavURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }

    remove=(code)=>{
        this.setState({isLoading:""})
        axios.post(removeFavURL(),removeFavBody(code))
            .then((res)=>{
                this.setState({isLoading:"d-none"})
                if(res.status===200 && res.data===1){
                    removeItemSuccess()
                    this.componentDidMount();
                }
                else {
                    requestFail()
                }
            })
            .catch((err)=>{
                this.setState({isLoading:"d-none"})
                requestFail()
            })
    }


    render() {

        let ProductList=this.state.DataList;
        let ProductListView=<ProductListPlaceholder/>;
        let OneStarView=<span>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let TwoStarView=<span>
            <AiFillStar className="text-success"/>
             <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let ThreeStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FourStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiOutlineStar className="text-success"/>
        </span>
        let FiveStarView=<span>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
            <AiFillStar className="text-success"/>
        </span>

        if(ProductList!==null){
            ProductListView=ProductList.map((list,i)=>{
                // Product Star Conditions
                let StarView;
                if(list[0]['star']==="1"){
                    StarView=OneStarView;
                }
                else if(list[0]['star']==="2") {
                    StarView=TwoStarView;
                }
                else if(list[0]['star']==="3") {
                    StarView=ThreeStarView;
                }
                else if(list[0]['star']==="4") {
                    StarView=FourStarView;
                }
                else if(list[0]['star']==="5") {
                    StarView=FiveStarView;
                }

                // Product Stock YES NO
                // Discount View Percentage
                let StockView=<span></span>;
                if(list[0]['stock']==="NO"){
                    StarView=<span className="top-left badge red">স্টক আউট</span>;
                }
                else if(list[0]['stock']==="YES" && list[0]['discount_type']==="Percentage" ){
                    StarView=<span className="top-left badge green">{list[0]['discount_percentage']} % অফ</span>
                }
                else if(list[0]['stock']==="YES" && list[0]['discount_type']==="Flat" ){
                    StarView=<span className="top-left badge green">{list[0]['flat_discount']} টাকা ডিস্কাউন্ট</span>
                }
                else if(list[0]['stock']==="YES" && list[0]['discount_type']==="NA" ){
                    StockView=<span></span>;
                }

                return(
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <div className="card animated zoomIn h-100 w-100 text-center">
                            <div className="badge-overlay">
                                {StockView}
                            </div>
                            <Link to={"/ProductDetails/"+list[0]['product_code']}>
                            <div className="card-body card-body-custom image-box">
                                <img className="w-100" src={list[0]['image']} />
                                <h5 className="card-title m-0">{list[0]['title']}</h5>
                                {StarView}
                            </div>
                            </Link>
                            <div className="input-group">
                                 <button onClick={this.remove.bind(this,list[0]['product_code'])} className="btn btn-danger card-order-btn w-100"> <FiTrash2/>  </button>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <Fragment>
                <div className="container-fluid mt-2 mt-4 mb-4">
                    <h4 className="section-title text-center">- পছন্দের তালিকা - </h4>
                    <div className="row text-center">
                        {ProductListView}
                    </div>
                </div>
                <FullScreenLoader isLoading={this.state.isLoading}/>
            </Fragment>
        );
    }
}

export default FavouriteList;