// Reviewed
import React, {Component,Fragment} from 'react';
import {Modal, Nav, Navbar} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FaBars} from "react-icons/fa";
import {BiBell, BiEnvelope, BiHomeSmile} from "react-icons/bi";
import {IoBagHandleSharp} from "react-icons/io5";
import axios from "axios";
import {CartListCountURL} from "../../APIServices/CartListCount";
import SessionHelper from "../../Helper/SessionHelper";
import {RetrieveCategories, StoreCategories} from "../../Helper/LocalForgeHelper";
import {SelectCategoryURL} from "../../APIServices/SelectCategoryRequest";
import {Redirect} from "react-router";
import {RequiredSearchKey} from "../../Helper/ToastMassageHelper";
import logo from "./../../Assets/Img/logo.png"
import {CgList, CgLogIn, CgLogOut, CgProfile} from "react-icons/cg";
import {BsSearch} from "react-icons/bs";
import {FiArrowRightCircle, FiHelpCircle} from "react-icons/fi";
import {AiOutlineHeart} from "react-icons/all";

class TopNavigationMobile extends Component {

    constructor() {
        super();
        this.state={
            SideNavState:"sideNavClose",
            ContentOverState:"ContentOverlayClose",
            DataList:null,
            Redirect:false,
            cartItem:0,
            searchKey:"",
            searchRedirect:false,
            searchModel:false,
        }
    }
    componentDidMount() {
        this.SelectCategory()
        this.cartItemCount();
    }
    SelectCategory=()=>{
        RetrieveCategories((StoreData)=>{
            if(StoreData!==null){
                this.setState({DataList:StoreData})
            }
            else {
                axios.get(SelectCategoryURL()).then((res)=>{
                    this.setState({DataList:res.data})
                    StoreCategories(res.data)
                })
            }
        })
    }
    logOut=()=>{
        SessionHelper.ClearAllSession();
        this.setState({Redirect:true})
    }
    pageRedirect=()=>{
        if(this.state.Redirect===true){
            return (
                <Redirect to="/"/>
            )
        }
    }
    cartItemCount(){
        axios.get(CartListCountURL()).then((res)=>{
            this.setState({cartItem:res.data})
        })
    }
    search=()=>{
        let searchKey=this.state.searchKey;
        if(searchKey.length===0){
            RequiredSearchKey();
        }
        else{
            this.setState({searchRedirect:true});
        }
    }
    searchRedirectPage=()=>{
        if(this.state.searchRedirect===true){
            return (
                <Redirect to={"/search/"+this.state.searchKey}/>
            )
        }
    }
    searchModelClose=()=>{
        this.setState({searchModel:false})
    }
    searchModelOpen=()=>{
        this.setState({searchModel:true})
    }
    MenuBarClickHandler=()=>{
        this.SideNavOpenClose();
    }
    ContentOverlayClickHandler=()=>{
        this.SideNavOpenClose();
    }
    SideNavOpenClose=()=>{
        let SideNavState= this.state.SideNavState;
        let ContentOverState= this.state.ContentOverState;
        if(SideNavState==="sideNavOpen"){
            this.setState({SideNavState:"sideNavClose",ContentOverState:"ContentOverlayClose"})
        }
        else{
            this.setState({SideNavState:"sideNavOpen",ContentOverState:"ContentOverlayOpen"})
        }
    }


    render() {

        let CategoryList=this.state.DataList;
        let CategoryListView= <Nav.Link> </Nav.Link>
        if(CategoryList!==null){
            CategoryListView=CategoryList.map((list,i)=>{
                return(
                    <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to={"/ProductList/"+list['cat_code']}><FiArrowRightCircle/> {list['cat_name']}</NavLink> </Nav.Link>
                )
            })
        }

        let LoginStatus=SessionHelper.getLogin();
        if(LoginStatus==="YES"){
            return (
                <Fragment>
                    <Navbar fluid={"true"} className="fixed-top d-flex justify-content-between shadow-sm p-2 m-0 navbar-mobile">
                        <a onClick={this.MenuBarClickHandler} className=" mx-2 navbar-brand"><FaBars/></a>
                        <div className=" float-right">
                            <NavLink exact className="btn theme-btn " to="/"><BiHomeSmile/> হোম</NavLink>
                            <a onClick={this.searchModelOpen} className="btn theme-btn mx-2 "><BsSearch/> সার্চ</a>
                            <NavLink exact className="btn theme-btn" to="/CartList"><IoBagHandleSharp/> {this.state.cartItem} আইটেম</NavLink>
                        </div>
                    </Navbar>
                    <div  className={this.state.SideNavState}>
                        <div className="navbar-mobile-top text-center">
                            <NavLink exact to="/"><img className="nav-logo" src={logo} alt=""/></NavLink>
                        </div>
                        {CategoryListView}
                        <hr className="bg-light mx-2"/>
                        <Nav.Link> <a  className="nav-item-top btn m-0 p-1" onClick={this.logOut}><CgLogOut/> লগ-আউট</a> </Nav.Link>
                        <Nav.Link><NavLink exact className="nav-item-top btn m-0 p-1 " to="/NotificationList"><BiBell/> বার্তা</NavLink></Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-top btn m-0 p-1" to="/fav"><AiOutlineHeart/> পছন্দের তালিকা</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-top btn m-0 p-1" to="/orderHistory"><CgList/> অর্ডার সমুহ</NavLink> </Nav.Link>
                        <hr className="bg-light mx-2"/>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/HowPurchase"><FiHelpCircle/> কিভাবে কিনবেন</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/About"><FiHelpCircle/> আমাদের সম্পর্কে </NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Contact"><BiEnvelope/> যোগাযোগ</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Privacy"><FiHelpCircle/> প্রাইভেসি</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Terms"><FiHelpCircle/> শর্ত সমূহ</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Refund"><FiHelpCircle/> ফেরত নীতি</NavLink> </Nav.Link>
                    </div>
                    <div onClick={this.ContentOverlayClickHandler}  className={this.state.ContentOverState}>
                    </div>
                    <Modal centered show={this.state.searchModel} onHide={this.searchModelClose}>
                        <Modal.Header closeButton>
                            <h6>আপনি কি খুঁজছেন?</h6>
                        </Modal.Header>
                        <Modal.Body className="bg-light">
                            <input onChange={e=>{this.setState({searchKey:e.target.value})}} type="text" className="form-control form-search-control" placeholder=""/>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={this.searchModelClose}>
                                Close
                            </button>
                            <button className="btn btn-dark"  onClick={this.search}>
                                <BsSearch/>  Search
                            </button>
                        </Modal.Footer>
                    </Modal>
                    {this.searchRedirectPage()}
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <Navbar fluid={"true"} className="fixed-top d-flex justify-content-between shadow-sm p-2 m-0 navbar-mobile">
                        <a onClick={this.MenuBarClickHandler} className=" mx-2 navbar-brand"><FaBars/></a>
                        <div className=" float-right">
                            <NavLink exact className="btn theme-btn " to="/"><BiHomeSmile/> হোম</NavLink>
                            <a onClick={this.searchModelOpen} className="btn theme-btn mx-2"><BsSearch/> সার্চ</a>
                            <NavLink exact className="btn theme-btn" to="/CartList"><IoBagHandleSharp/> {this.state.cartItem} আইটেম</NavLink>
                        </div>
                    </Navbar>
                    <div  className={this.state.SideNavState}>
                        <div className="navbar-mobile-top text-center">
                            <NavLink exact to="/"><img className="nav-logo" src={logo} alt=""/></NavLink>
                        </div>
                        {CategoryListView}
                        <hr className="bg-light m-1"/>
                        <Nav.Link> <NavLink exact className="nav-item-top btn m-0 p-1" to="/Login"><CgLogIn/> লগইন</NavLink> </Nav.Link>
                        <Nav.Link><NavLink exact className="nav-item-top btn m-0 p-1 " to="/NotificationList"><BiBell/> বার্তা</NavLink></Nav.Link>
                        <hr className="bg-light mx-2"/>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/HowPurchase"><FiHelpCircle/> কিভাবে কিনবেন</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/About"><FiHelpCircle/> আমাদের সম্পর্কে </NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Contact"><BiEnvelope/> যোগাযোগ</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Privacy"><FiHelpCircle/> প্রাইভেসি</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Terms"><FiHelpCircle/> শর্ত সমূহ</NavLink> </Nav.Link>
                        <Nav.Link> <NavLink exact className="nav-item-mobile mx-1" to="/Refund"><FiHelpCircle/> ফেরত নীতি</NavLink> </Nav.Link>
                    </div>
                    <div onClick={this.ContentOverlayClickHandler}  className={this.state.ContentOverState}>
                    </div>
                    <Modal centered show={this.state.searchModel} onHide={this.searchModelClose}>
                        <Modal.Header closeButton>
                            <h6>আপনি কি খুঁজছেন?</h6>
                        </Modal.Header>
                        <Modal.Body className="bg-light">
                            <input onChange={e=>{this.setState({searchKey:e.target.value})}} type="text" className="form-control form-search-control" placeholder=""/>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-dark" onClick={this.searchModelClose}>
                                Close
                            </button>
                            <button onClick={this.search} className="btn btn-dark"  >
                                <BsSearch/>  Search
                            </button>
                        </Modal.Footer>
                    </Modal>
                    {this.searchRedirectPage()}
                </Fragment>
            );
        }
    }
}
export default TopNavigationMobile;