import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import TopBannerSlider from "../../Components/Home/TopBannerSlider";
import TopBannerSliderMobile from "../../Components/Home/TopBannerSliderMobile";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import PopularCategories from "../../Components/Home/PopularCategories";
import TrendingNowProducts from "../../Components/Home/TrendingNowProducts";
import NewArrival from "../../Components/Home/NewArrival";
import SpecialCollection from "../../Components/Home/SpecialCollection";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";
class HomePage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>


               <TopNavigation/>

                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>

                <div className="desktop">
                    <TopBannerSlider/>
                </div>
                <div className="mobile">
                    <TopBannerSliderMobile/>
                </div>
                <PopularCategories/>
                <TrendingNowProducts/>

                <div className="shadow-sm bg-white py-5">
                    <NewArrival/>
                </div>

                <SpecialCollection/>

                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>

            </Fragment>
        );
    }
}

export default HomePage;