// reviewed
import React, {Component,Fragment} from 'react';
class AboutDescriptionPlaceholder extends Component {
    render() {
        return (
            <Fragment>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="ph-item">
                    <div className="ph-col-12">
                        <div className="ph-row">
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                            <div className="ph-col-12 small"/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default AboutDescriptionPlaceholder;