import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import ProductDetails from "../../Components/ProductDetails/ProductDetails";
import Footer from "../../Components/Common/Footer";
import ProductDescription from "../../Components/ProductDetails/ProductDescription";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import axios from "axios";
import {ProductDetailsURL} from "../../APIServices/ProductDetailsRequest";
import ProductDetailsPlaceholder from "../../Components/ProductDetails/ProductDetailsPlaceholder";
import ProductDescriptionPlaceholder from "../../Components/ProductDetails/ProductDescriptionPlaceholder";
import FooterMobile from "../../Components/Common/FooterMobile";
import SessionHelper from "../../Helper/SessionHelper";
import {requestFail} from "../../Helper/ToastMassageHelper";
import {Redirect} from "react-router";
class ProductDetailsPage extends Component {

    constructor({match}) {
        super();
        this.state={
            code:match.params.code,
            DataList:null,
            RedirectHome:false,
        }
    }
    componentDidMount() {
        let WindowPath=window.location.pathname;
        SessionHelper.setProductLocation(WindowPath)
        window.scroll(0,0);
        this.getProductDetails();
    }

    getProductDetails=()=>{
        axios.get(ProductDetailsURL(this.state.code)).then(response=> {
            if(response.status===200 ){
                this.setState({DataList:response.data})
            }
            else{
                requestFail();
                this.setState({RedirectHome:true})
            }
        }).catch((error)=>{
            requestFail();
            this.setState({RedirectHome:true})
        })
    }

    RedirectToHomePage=()=>{
        if(this.state.RedirectHome===true){
            return (
                <Redirect to="/"/>
            )
        }
    }

    render() {
        let DataList=this.state.DataList;
        if(DataList!==null){
            return (
                <Fragment>
                    <TopNavigation/>
                    <div className="mobile nav-mobile-top">
                        <TopNavigationMobile/>
                    </div>
                    <ProductDetails  DataList={this.state.DataList}/>
                    <ProductDescription DataList={this.state.DataList}/>
                    <div className="desktop">
                        <Footer/>
                    </div>
                    <div className="mobile">
                        <FooterMobile/>
                    </div>
                    {this.RedirectToHomePage()}
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <TopNavigation/>
                    <div className="mobile nav-mobile-top">
                        <TopNavigationMobile/>
                    </div>
                    <ProductDetailsPlaceholder/>
                    <ProductDescriptionPlaceholder/>
                    <div className="desktop">
                        <Footer/>
                    </div>
                    <div className="mobile">
                        <FooterMobile/>
                    </div>
                    {this.RedirectToHomePage()}
                </Fragment>
            );
        }
    }
}

export default ProductDetailsPage;