import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import VideoList from "../../Components/VideoList/VideoList";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";

class VideoListPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <VideoList/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}

export default VideoListPage;