// Completed By Rabbil Hasan
// 14-05-2014 5:43AM
import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import RefundDescription from "../../Components/RefundDescription/RefundDescription";
import Footer from "../../Components/Common/Footer";
import TopNavigationMobile from "../../Components/Common/TopNavigationMobile";
import FooterMobile from "../../Components/Common/FooterMobile";

class RefundPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>

                <TopNavigation/>
                <div className="mobile nav-mobile-top">
                    <TopNavigationMobile/>
                </div>
                <RefundDescription/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}
export default RefundPage;