import React, {Component,Fragment} from 'react';
import axios from "axios";
import {OrderInvoiceProductListURL} from "../../APIServices/OrderListRequest";
import NoDataFound from "../Common/NoDataFound";
import CartListPlaceholder from "../CartList/CartListPlaceholder";
import {Modal} from "react-bootstrap";
import FullScreenLoader from "../Common/FullScreenLoader";
import {
    requestFail,
    ReviewerCommentsRequired,
    ReviewerNameRequired,
    ReviewSuccess
} from "../../Helper/ToastMassageHelper";
import {SubmitReviewBody, SubmitReviewURL} from "../../APIServices/SubmitReviewRequest";

class OrderHistoryDetails extends Component {
    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            DataList:null,
            nodata:false,
            ReviewModal:false,
            ReviewName:"",
            ReviewComments:"",
            product_code:"",
            ReviewRating:"5",
            product_name:""
        }
    }

    ReviewNameOnChange=(event)=>{
          let ReviewName=  event.target.value;
          this.setState({ReviewName:ReviewName})
    }

    ReviewCommentsOnChange=(event)=>{
        let ReviewComments=  event.target.value;
        this.setState({ReviewComments:ReviewComments})
    }

    ReviewRatingOnChange=(event)=>{
        let ReviewRating=  event.target.value;
        this.setState({ReviewRating:ReviewRating})
    }



    ReviewModalOpen=()=>{
        this.setState({ ReviewModal:true})
    }

    ReviewModalClose=()=>{
        this.setState({ ReviewModal:false})
    }

    review=(code,product_name)=>{
        this.setState({product_name:product_name})
        this.setState({product_code:code});
        this.ReviewModalOpen();
    }

    submitReview=()=>{
        let name=this.state.ReviewName;
        let comments=this.state.ReviewComments;
        let code=this.state.product_code;
        let rating=this.state.ReviewRating;
        let product_name=this.state.product_name;

        if(name.length===0){
            ReviewerNameRequired();
        }
        else if(comments.length===0){
            ReviewerCommentsRequired()
        }
        else {
            this.setState({isLoading:""})
            axios.post(SubmitReviewURL(),SubmitReviewBody(code,product_name,name,comments,rating))
                .then((res)=>{
                    if(res.status===200 && res.data===1){
                        this.setState({isLoading:"d-none"});
                        ReviewSuccess();
                        this.ReviewModalClose();
                    }
                    else {
                        this.setState({isLoading:"d-none"});
                        requestFail();
                        this.ReviewModalClose();
                    }
                })
                .catch((err)=>{
                    this.setState({isLoading:"d-none"});
                    requestFail();
                    this.ReviewModalClose();
                })
        }



    }

    componentDidMount() {
        this.SelectItems();
    }


    SelectItems=()=>{
        axios.get(OrderInvoiceProductListURL(this.props.invoice))
            .then((res)=>{
                if(res.status===200 && res.data.length>0){
                    this.setState({DataList:res.data})
                }
                else if(res.data.length===0){
                    this.setState({nodata:true})
                }
                else{
                    this.setState({nodata:true})
                }
            }).catch((err)=>{
            this.setState({nodata:true})
        })
    }


    render() {

        let DataList=this.state.DataList;
        let DataView=<CartListPlaceholder/>
        if(DataList!==null && this.state.nodata===false){
            DataView=  DataList.map((list,i)=>{
                let discountPrice=list['discount_price'];
                let unitPrice=list['unit_price'];
                let PriceView= <h6 className="cart-list-text">মূল্যঃ {discountPrice} টাকা</h6>
                if(discountPrice===null){
                    PriceView= <h6 className="cart-list-text">মূল্যঃ {unitPrice} টাকা</h6>
                }
                if(list['cat_code']===process.env.REACT_APP_MANGO_CODE){
                    return(
                        <div className="col-lg-12 animated slideInUp col-md-12 col-sm-12 col-12 p-1">
                            <div className="container shadow-sm bg-white">
                                <div className="row">
                                    <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                        <img className="w-75" src={list['img']}/>
                                        {PriceView}
                                    </div>
                                    <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                    <h6 className="cart-list-text">নামঃ {list['product_name']}</h6>
                                                    <h6 className="cart-list-text">ঝুড়ির সাইজঃ  {list['lot_size']} {list['product_unit']}</h6>
                                                    <h6 className="cart-list-text">পরিমাণঃ {list['product_quantity']} টি </h6>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                    <h6 className="cart-list-text">প্যাকেজিংঃ {list['packing_charge']} টাকা</h6>
                                                    <h6 className="cart-list-text">মোট মূল্যঃ {list['total_price']} টাকা </h6>
                                                    <button onClick={this.review.bind(this,list['product_code'],list['product_name'])} className="btn btn-sm btn-danger">রিভিউ দিন</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    return(
                        <div className="col-lg-12 animated slideInUp col-md-12 col-sm-12 col-12 p-1">
                            <div className="container shadow-sm bg-white">
                                <div className="row">
                                    <div className="col-lg-4 text-center p-2 m-auto col-md-4 col-sm-4 col-4">
                                        <img className="w-75" src={list['img']}/>
                                        {PriceView}
                                    </div>
                                    <div className="col-lg-8 p-2 m-auto col-md-8 col-sm-8 col-8">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                    <h6 className="cart-list-text">নামঃ {list['product_name']}</h6>
                                                    <h6 className="cart-list-text">পরিমাণঃ {list['product_quantity']} টি </h6>
                                                    <h6 className="cart-list-text">প্যাকেজিংঃ {list['packing_charge']} টাকা </h6>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                                                    <h6 className="cart-list-text">মোট মূল্যঃ {list['total_price']} টাকা </h6>
                                                    <button onClick={this.review.bind(this,list['product_code'])} className="btn btn-sm btn-danger">রিভিউ দিন</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        else if(this.state.nodata===true){
            DataView=<NoDataFound/>
        }


        return (
            <Fragment>
                <div className="container my-4 ">
                    <div className="col-lg-12 p-0 col-md-12 col-sm-12 col-12">
                        <div className="container">
                            <div className="row">
                                {DataView}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.ReviewModal} onHide={this.ReviewModalClose}>
                    <Modal.Header closeButton>
                        <h6>ইউজার রিভিউ</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <label className="form-label mt-2">আপনার নামঃ</label>
                        <input onChange={this.ReviewNameOnChange} type="text" className="form-control"/>

                        <label className="form-label mt-2">রেটিংঃ</label>
                        <select onChange={this.ReviewNameOnChange} type="text" className="form-control">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option selected value="5">5</option>

                        </select>

                        <label className="form-label mt-2">মন্তব্যঃ</label>
                        <textarea  onChange={this.ReviewCommentsOnChange} rows={3} type="text" className="form-control"/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={this.ReviewModalClose}>
                            বাতিল
                        </button>
                        <button  className="btn btn-dark" onClick={this.submitReview}>
                           সাবমিট
                        </button>
                    </Modal.Footer>
                </Modal>
                <FullScreenLoader isLoading={this.state.isLoading}/>
            </Fragment>
        );
    }
}

export default OrderHistoryDetails;