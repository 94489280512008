// reviewed
import React, {Component,Fragment} from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import {SiteInfoURL} from "../../APIServices/SiteInfoRequest";
import AboutDescriptionPlaceholder from "../AboutDescription/AboutDescriptionPlaceholder"
class AboutDescription extends Component {
    constructor() {
        super();
        this.state={
            des:null
        }
    }
    componentDidMount() {
        axios.get(SiteInfoURL()).then((res)=>{
            this.setState({ des:(res.data)[0]['about']})
        })
    }
    render() {
        let des=this.state.des;
        let desView=<AboutDescriptionPlaceholder/>
        if(des!==null){
            desView= <div className="animated fadeIn">{ReactHtmlParser(des)}</div>;
        }
        return (
            <Fragment>
                <div className="container shadow-sm bg-white my-4 ">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-4">
                                {desView}
                            </div>
                        </div>
                </div>
            </Fragment>
        );
    }
}

export default AboutDescription;