import SessionHelper from "../Helper/SessionHelper";
class AddToCartRequest {
    AddToCartURL(CartName){
        return process.env.REACT_APP_BASEURL+CartName;
    }
    AddToCartBody(cat_code,product_code,lot_size,product_quantity){
            return{
                cat_code:cat_code,
                product_code:product_code,
                lot_size:lot_size,
                product_quantity:product_quantity,
                mobile:SessionHelper.getMobile()
            }
    }
}
export const {AddToCartURL,AddToCartBody}=new AddToCartRequest();