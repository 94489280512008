// Reviewed
import React, {Component,Fragment} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {ProductListSliderURL} from "../../APIServices/ProductListRequest";
import TopBannerSliderPlaceholder from "./TopBannerSliderPlaceholder";
class TopBannerSlider extends Component {
    constructor() {
        super();
        this.state={
            DataList:null
        }
    }
    componentDidMount() {
        this.SelectProductList()
    }

    SelectProductList=()=>{
        axios.get(ProductListSliderURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }
    render() {
        let settings = {
            autoplay:true,
            autoplaySpeed:3000,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        let ProductList=this.state.DataList;
        let ProductListView=<TopBannerSliderPlaceholder/>;
        if(ProductList!==null){
            ProductListView=ProductList.map((list,i)=>{
                return (
                    <div className="top-banner mx-0 px-0 shadow-sm bg-white container-fluid">
                        <div className="row m-0 p-0">
                            <Link to={"/ProductDetails/"+list['product_code']}  className="col-lg-12 m-0 p-0 col-md-12 col-sm-12 col-12 text-center">
                                <img className="banner-item-img animated slideInDown" src={list['image']} alt=""/>
                            </Link>
                        </div>
                    </div>
                )
            })

        }

        return (
            <Fragment>
                <div className="slider-overflow">
                    <Slider {...settings}>
                        {ProductListView}
                    </Slider>
                </div>
            </Fragment>

        );
    }
}
export default TopBannerSlider;