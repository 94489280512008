import React, {Component,Fragment} from 'react';
import AppRoute from "./AppRoute/AppRoute";
import {BrowserRouter} from "react-router-dom";
import "./Assets/CSS/bootstrap.min.css";
import "./Assets/CSS/animate.min.css";
import "./Assets/CSS/placeholder-loading.min.css";
import "./Assets/CSS/global.css";
import localForage from "localforage";
import axios from "axios";
import {VisitorDetailsURL} from "./APIServices/VisitorDetailsRequest";
class App extends Component {

    componentDidMount() {
        localForage.clear(function (err) {
        }). then(r =>{})

        axios.get(VisitorDetailsURL())
            .then((res)=>{}).catch((err)=>{})
    }


    render() {
    return (
        <Fragment>
          <BrowserRouter>
            <AppRoute/>
          </BrowserRouter>
        </Fragment>
    );
  }
}

export default App;
