//Completed By Rabbil
import React, {Component,Fragment} from 'react';
import paymentImage from "../../Assets/Img/Payment.png";
class FooterMobile extends Component {
    render() {
        return (
            <Fragment>
                <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                <footer className="site-footer">
                <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                        <div className="col-md-8  col-sm-6 col-xs-12">
                            <p className="copyright-text ">সর্বস্বত্ব সংরক্ষিত 2021-2022
                                <a className="FooterInfoLinkText mx-1 " href="/">  কৃষাণ বাংলা</a>
                            </p>
                        </div>
                    </div>
                    <img src={paymentImage} width="100%" alt="" />
                </div>
                </footer>
            </Fragment>
        );
    }
}
export default FooterMobile;