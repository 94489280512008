import SessionHelper from "../Helper/SessionHelper";

class OrderListRequest {
    OrderInvoiceListURL(){
        return process.env.REACT_APP_BASEURL+"OrderHistoryInvoices/"+SessionHelper.getMobile();
    }
    OrderInvoiceProductListURL(Invoice){
        return process.env.REACT_APP_BASEURL+"OrderHistoryInvoiceItemData/"+Invoice;
    }

}
export const {OrderInvoiceListURL,OrderInvoiceProductListURL} =new OrderListRequest();