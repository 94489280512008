// Reviewed
import React, {Component,Fragment} from 'react';
import ReviewList from "./ReviewList";
import ReactHtmlParser from 'react-html-parser';
import SimilarProductList from "./SimilarProductList";
class ProductDescription extends Component {
    render() {
        let ProductData=this.props.DataList;
        let des=ProductData['ProductDetails'][0]['details'];
        let code=ProductData['ProductDetails'][0]['product_code'];
        return (
            <Fragment>
                <div className="container animated fadeIn">
                    <div className="row">
                        <div className="col-lg-6 p-1 col-md-6 col-sm-12 col-12">
                            <div className="shadow-sm bg-white p-2">
                                <div className="col-lg-12 description col-md-12 col-sm-12 col-12">
                                    {ReactHtmlParser(des)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-1 col-md-6 col-sm-12 col-12">
                            <div className="shadow-sm bg-white p-2">
                                <ReviewList code={code}/>
                            </div>
                        </div>
                    </div>
                </div>
                <SimilarProductList code={code}/>
            </Fragment>
        );
    }
}
export default ProductDescription;