// Reviewed
import React, {Component,Fragment} from 'react';
import img from "../../Assets/Img/ph-logo.png"
class ProductDetailsPlaceholder extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="row shadow-sm p-2 bg-white">
                                <div className="p-1  col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="container">
                                        <div className="row">
                                            <div className="p-0 text-center m-0 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <img className="w-50" src={img}/>
                                            </div>
                                            <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                <img onClick={this.imgOnclick} className="w-100" src={img} alt=""/>
                                            </div>
                                            <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                <img onClick={this.imgOnclick} className="w-100 " src={img} alt=""/>
                                            </div>
                                            <div className="px-1 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                <img onClick={this.imgOnclick} className="w-100 " src={img} alt=""/>
                                            </div>
                                            <div className="p-0 m-0 image-box col-lg-3 col-md-3 col-sm-3 col-3">
                                                <img onClick={this.imgOnclick} className="w-100 " src={img} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="ph-item">
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ph-item">
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ph-item">
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ph-item">
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ph-item">
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                                <div className="ph-col-12 small"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ProductDetailsPlaceholder;