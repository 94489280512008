// Completed Rabbil Hasan
import React, {Component,Fragment} from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    emailRequired,
    invalidEmail,
    mobileRequired, otpSuccessEmail,
    otpSuccessMobile,
    requestFail
} from "../../Helper/ToastMassageHelper";
import ValidationHelper from "../../Helper/ValidationHelper";
import axios from "axios";
import {OTPurl} from "../../APIServices/OTPRequest";
import {Redirect} from "react-router";
import SessionHelper from "../../Helper/SessionHelper";
import FullScreenLoader from "../Common/FullScreenLoader";
class Login extends Component {
    constructor() {
        super();
        this.state={
            isLoading:"d-none",
            phone:"",
            email:"",
            Redirect:false
        }
    }

    pageRedirect=()=>{
        if(this.state.Redirect===true){
                return(
                    <Redirect to="/otp"/>
                )
        }
    }

    onLogin=()=>{
        let phone=this.state.phone;
        let email=this.state.email;
        let countryCode=phone.substring(0,3);

        if(phone.length===0){
            mobileRequired();
        }
        else if(email.length===0){
            emailRequired();
        }
        else if(!(ValidationHelper.EmailRegx).test(email)){
            invalidEmail();
        }
        else{
            this.setState({isLoading:""})
            axios.get(OTPurl(phone,email,countryCode))
                .then((res)=>{
                    if(res.status===200 && res.data===1){
                        this.setState({isLoading:"d-none"})
                        if(countryCode==="880"){
                            SessionHelper.setOTPNote("আপনার মোবাইলে 6 সংখ্যার ভেরিফিকেশন কোড পাঠানো হয়েছে")
                            SessionHelper.setMobile(phone)
                            SessionHelper.setEmail(email)
                            otpSuccessMobile();
                            this.setState({ Redirect:true})
                        }
                        else {
                            SessionHelper.setMobile(phone)
                            SessionHelper.setEmail(email)
                            SessionHelper.setOTPNote("আপনার ইমেইল ঠিকানায় 6 সংখ্যার ভেরিফিকেশন কোড পাঠানো হয়েছে")
                            otpSuccessEmail()
                            this.setState({ Redirect:true})
                        }
                    }
                    else {
                        this.setState({isLoading:"d-none"})
                        requestFail();
                    }
                })
                .catch((err)=>{
                    this.setState({isLoading:"d-none"})
                    requestFail();
                })
        }
    }


    render() {
        return (
            <Fragment>
                <div className="container ">
                    <div className="row d-flex animated zoomIn justify-content-center">
                        <div className="col-md-10 my-4 col-lg-10 col-12 col-sm-12">
                            <div className="container shadow-sm p-2 bg-white">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-6  col-lg-6 col-sm-12 col-12  ">
                                        <br/>
                                        <div className="login-form">
                                            <h5 className="login-title text-center">লগ-ইন করুন</h5>
                                            <PhoneInput autoFormat={false} inputProps={{autoFocus: true}} inputClass="form-control  w-100 mt-3" country={'bd'} value={this.state.phone} onChange={phone => this.setState({phone })}/>
                                            <input onChange={(e)=>{this.setState({email:e.target.value})}} type="text" className="form-control mt-3" placeholder="ইমেল"/>
                                            <button onClick={this.onLogin} className="btn btn-dark mt-3 btn-block">লগ-ইন</button>
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.pageRedirect()}
                <FullScreenLoader isLoading={this.state.isLoading}/>
                <br/><br/><br/><br/><br/><br/><br/>
            </Fragment>
        );
    }
}
export default Login;