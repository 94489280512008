// Reviewed
import React, {Component,Fragment} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import axios from "axios";
import {ProductListSliderURL} from "../../APIServices/ProductListRequest";
import TopBannerSliderPlaceholderMobile from "./TopBannerSliderPlaceholderMobile";
class TopBannerSliderMobile extends Component {

    constructor() {
        super();
        this.state={
            DataList:null
        }
    }
    componentDidMount() {
        this.SelectProductList()
    }
    SelectProductList=()=>{
        axios.get(ProductListSliderURL()).then((res)=>{
            this.setState({DataList:res.data})
        })
    }

    render() {
        let settings = {
            autoplay:true,
            autoplaySpeed:3000,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        let ProductList=this.state.DataList;
        let ProductListView=<TopBannerSliderPlaceholderMobile/>;
        if(ProductList!==null){

            ProductListView=ProductList.map((list,i)=>{
                return(
                    <div className="top-banner  shadow-sm bg-white m-0 p-0  container-fluid ">
                        <div className="row m-0 p-0">
                            <Link to={"/ProductDetails/"+list['product_code']} className="col-lg-12 col-md-12 m-0 p-0  col-sm-12 col-12 text-center">
                                <img className="banner-item-img text-center animated slideInDown" src={list['mobile_slider']} alt=""/>
                            </Link>
                        </div>
                    </div>
                )
            })
        }
        return (
            <Fragment>
                <div className="slider-overflow" >
                    <Slider {...settings}>
                        {ProductListView}
                    </Slider>
                </div>
            </Fragment>

        );
    }
}
export default TopBannerSliderMobile;