class ProductListRequest {
    ProductListCategoryURL(code){
        return process.env.REACT_APP_BASEURL+"ProductListCategory/"+code
    }

    ProductListNewURL(){
        return process.env.REACT_APP_BASEURL+"ProductListNEW"
    }

    ProductListCOLLECTIONURL(){
        return process.env.REACT_APP_BASEURL+"ProductListCOLLECTION"
    }

    ProductListTRENDINGURL(){
        return process.env.REACT_APP_BASEURL+"ProductListTRENDING"
    }

    ProductListSliderURL(){
        return process.env.REACT_APP_BASEURL+"SelectSlider"
    }

    ProductListRandomURL(){
        return process.env.REACT_APP_BASEURL+"ProductListRandom"
    }

    ProductListSearchURL(code){
        return process.env.REACT_APP_BASEURL+"ProductListBySearch/"+code
    }


}
export const {
    ProductListSearchURL,
    ProductListRandomURL,
    ProductListSliderURL,
    ProductListCategoryURL,
    ProductListNewURL,
    ProductListCOLLECTIONURL,
    ProductListTRENDINGURL,
}=new ProductListRequest();