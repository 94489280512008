import React, {Component,Fragment} from 'react';
import TopNavigation from "../../Components/Common/TopNavigation";
import VideoDetails from "../../Components/VideoDetails/VideoDetails";
import VideoComments from "../../Components/VideoDetails/VideoComments";
import Footer from "../../Components/Common/Footer";
import FooterMobile from "../../Components/Common/FooterMobile";

class VideoDetailsPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <VideoDetails/>
                <VideoComments/>
                <div className="desktop">
                    <Footer/>
                </div>
                <div className="mobile">
                    <FooterMobile/>
                </div>
            </Fragment>
        );
    }
}

export default VideoDetailsPage;