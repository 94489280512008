import React, {Component,Fragment} from 'react';

class VideoDetails extends Component {
    render() {
        return (
            <Fragment>
                <div className="container mt-4 mb-2 justify-content-center">
                    <div className="videoTopSection bg-white p-3">
                        <a href="/">Home</a> /
                        <a className="ml-2" href="/">VideoDetails</a>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                            <div className="card videoDetailsCard">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/AEIVhBS6baE" allowFullScreen></iframe>
                                </div>
                                <p className="date mt-2">Title description, 10 May, 2021</p>
                                <h5 className="videoDetailsTitle">মোবাইল অ্যাপে মিলছে রাজশাহীর আম</h5>
                                <p className="videoDetailsDes text-justify">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয়সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে নিশ্চয়তা থাকবে ঘরে বসে রাসায়নিকমুক্ত আম পাওয়ার। প্রবাসীরাও এই অ্যাপের মাধ্যমে দেশে তাদের স্বজনদের কাছে রাজশাহীর আম পৌঁছে দেওয়ার সুবিধা পাচ্ছেন। রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয়সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে নিশ্চয়তা থাকবে ঘরে বসে রাসায়নিকমুক্ত আম পাওয়ার। প্রবাসীরাও এই অ্যাপের মাধ্যমে দেশে তাদের স্বজনদের কাছে রাজশাহীর আম পৌঁছে দেওয়ার সুবিধা পাচ্ছেন।</p>
                                <p className="videoDetailsDes text-justify">রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয়সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে নিশ্চয়তা থাকবে ঘরে বসে রাসায়নিকমুক্ত আম পাওয়ার। প্রবাসীরাও এই অ্যাপের মাধ্যমে দেশে তাদের স্বজনদের কাছে রাজশাহীর আম পৌঁছে দেওয়ার সুবিধা পাচ্ছেন। রাজশাহীর আমের অ্যাপভিত্তিক বিক্রয়সেবা চালু হয়েছে। এর মাধ্যমে শুধু রাজশাহীর আম সরবরাহ করা হবে। সেই সঙ্গে নিশ্চয়তা থাকবে ঘরে বসে রাসায়নিকমুক্ত আম পাওয়ার। প্রবাসীরাও এই অ্যাপের মাধ্যমে দেশে তাদের স্বজনদের কাছে রাজশাহীর আম পৌঁছে দেওয়ার সুবিধা পাচ্ছেন।</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default VideoDetails;