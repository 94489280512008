// Reviewed
import React, {Component,Fragment} from 'react';
class ReviewListPlaceholder extends Component {
    render() {
        return (
            <Fragment>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                                <div className="ph-col-12 small"/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </Fragment>
        );
    }
}

export default ReviewListPlaceholder;