// Reviewed
import React, {Component,Fragment} from 'react';
import img from "../../Assets/Img/ph-logo.png"
class TopBannerSliderPlaceholderMobile extends Component {
    render() {
        return (
            <Fragment>
                <div className="top-banner m-0 p-0 shadow-sm bg-white  container-fluid">
                    <div className="row m-0 p-0">
                        <div className="col-lg-12  m-0 p-0 col-md-12 p-2 col-sm-12 col-12 text-center">
                            <div className="ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-12 small"/>
                                            <div className="ph-col-12 small"/>
                                            <div className="ph-col-12 small"/>
                                        </div>
                                    </div>
                                </div>
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                        <div className="ph-col-12 small"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default TopBannerSliderPlaceholderMobile;